/*
// .search--location--header
*/
@import "../variables";
@import "../svg";
@import "../mixins/direction";

$local-suggestion-transition: 0.2s;

@mixin local-input-focus-state() {
    @if ($header-type == white) {
        .search__input:focus {
            @content;
        }
    } @else {
        .search__input:focus,
        &.search--has-suggestions.search--suggestions-open .search__input {
            @content;
        }
    }
}

.search--location--header {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    .search__body {
        position: relative;
        z-index: 25;
    }
    .search__form {
        margin-bottom: 12px;
        max-width: 370px;
        width: 100%;
        height: 40px;
        display: flex;
    }
    .search__border {
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // transition: box-shadow .15s, background .15s;
    }
    // .search__categories {
    //     margin: 2px;
    //     padding: 0 14px;
    //     appearance: none;
    //     border: none;
    //     background-color: transparent;
    //     color: $header-search-input-default-font-color;
    //     font-size: 14px;
    //     transition: background-color .2s;
    //     max-width: 30%;
    //     cursor: pointer;
    //     border-radius: 0;
    //     background-image: url(svg-select-arrow($header-search-input-default-arrows-color));
    //     background-repeat: no-repeat;
    //     background-size: 5px 10px;

    //     @include direction {
    //         #{$border-inline-end}: 2px solid $header-search-input-default-border-color;
    //         #{$padding-inline-end}: 31px;
    //         #{$margin-inline-end}: 0;
    //         background-position: center $inline-end 13px;
    //     }

    //     &:hover {
    //         background-color: #f5f5f5;
    //     }
    //     &:focus {
    //         outline: none;
    //     }
    // }
    .search__input {
        border-style: none;
        border-radius: 0;
        padding: 0;
        background: #fff;
        width: 1px;
        flex-grow: 1;
        font-size: 16px;
        transition: color 0.15s;
        color: #c5c4c8;
        padding-bottom: 10px;

        &::placeholder {
            color: $header-search-input-default-placeholder;
        }
        & ~ .search__border {
            background: $header-search-input-default-bg;
            border-bottom: 1px solid #dcdcdc;
            // box-shadow: $header-search-input-default-shadow;
        }
    }
    .search__input:hover {
        color: $header-search-input-hover-font-color;

        &::placeholder {
            color: $header-search-input-hover-placeholder;
        }
        & ~ .search__border {
            background: $header-search-input-hover-bg;
            // box-shadow: $header-search-input-hover-shadow;
        }
        & ~ .search__button {
            fill: #c5c4c8;

            &:hover {
                fill: #c5c4c8;
            }
        }
    }
    @include local-input-focus-state {
        outline: none;
        color: $header-search-input-focus-font-color;

        &::placeholder {
            color: $header-search-input-focus-placeholder;
        }
        & ~ .search__border {
            background: $header-search-input-focus-bg;
            // box-shadow: $header-search-input-focus-shadow;
            box-shadow: #ccc;
        }
        & ~ .search__button {
            fill: $header-search-input-focus-icon-color;

            &:hover {
                fill: $header-search-input-focus-icon-hover-color;
            }
        }
    }
    .search__button {
        // border: none;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-style: none;
        padding: 0;
        background: #fff;
        flex-grow: 0;
        width: $header-search-height;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: #c5c4c8;
        transition: fill 0.15s;

        &:hover,
        &:focus {
            outline: none;
            fill: #dcdcdc;
        }
    }

    @if ($header-type == white) {
        .search__suggestions {
            $local-expand: 8px;
            $local-padding-top: 6px;
            $local-padding-bottom: 10px;

            z-index: -1;
            position: absolute;
            box-shadow: $header-search-suggestions-shadow;
            background: $header-search-suggestions-bg;
            // left: -$local-expand;
            // top: -$local-expand/2;
            top: 40px;
            // width: calc(100% + #{$local-expand * 2});
            width: calc(100%);
            // padding-top: $header-search-height + $local-expand + $local-padding-top;
            // padding-top: 15px;
            padding-bottom: $local-padding-bottom;
            border-radius: 2px;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: opacity $local-suggestion-transition,
                visibility 0s $local-suggestion-transition;
        }
        &.search--has-suggestions.search--suggestions-open {
            .search__suggestions {
                transition-delay: 0s, 0s;
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                overflow-y: scroll;
                // min-height: 400px;
                // height: 100%;
            }
        }
    } @else {
        display: block;
        height: $header-search-height;

        .search__border {
            z-index: -1;
        }
        .search__body {
            display: flex;
            flex-direction: column;
        }
        .search__categories {
            margin: 0;

            @include direction {
                #{$border-start-start-radius}: $header-search-border-radius;
                #{$border-end-start-radius}: $header-search-border-radius;
                #{$border-inline-end}: 1px solid
                    $header-search-input-default-border-color;
            }

            &:hover {
                background-color: rgba(#000, 0.05);
            }
        }
        .search__suggestions {
            $local-padding-top: 6px;
            $local-padding-bottom: 10px;

            left: 0;
            top: 0;
            width: calc(100%);
            border-top: 1px solid $header-search-suggestions-divider-color;
            padding-top: $local-padding-top;
            padding-bottom: $local-padding-bottom;
            display: none;
        }
        &.search--has-suggestions.search--suggestions-open {
            .search__suggestions {
                display: block;
            }
        }
    }
}
