/*
// .site
*/
@import '../variables';


.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;
    padding-top: 29px;
    margin-bottom: 100px;
}
.site__footer {
    flex-shrink: 0;
}